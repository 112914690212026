export const api_version = 2;
export const app_version = '2.55';
export const build = '2024.09.30.15.30'; 
export const dev_env = false;
export const dev_exigent_admin = false;
export const server = dev_env ? 'http://aft.local.192.168.4.40.nip.io' : 'https://aftplatform.com';

export const gdl_version = 2;
export const gdl_server = dev_env ? 'http://globaldata.local.192.168.4.40.nip.io' : 'https://globaldata.link';

export const hsa_version = 1;
export const hsa_server = 'https://homesafealerts.com';

export const omnishield_server = dev_env ? 'http://omnishield.local.192.168.4.40.nip.io' : 'https://api.aftplatform.com';
export const omnishield_version = 3;

export default {
    apps: {
        ios: 'https://apps.apple.com/us/app/applied-fire-technologies/id1394113289',
        android: 'https://play.google.com/store/apps/details?id=com.aftplatform.aft&hl=en_US&gl=US'
    },
    app_version: app_version,
    build: build,
    dev_env: dev_env,
    dev_exigent_admin: dev_exigent_admin,
    external: {
        gdl: {
            server: gdl_server,
            url: `${gdl_server}/api/v${gdl_version}`,
            sockets: dev_env ? `${gdl_server}:4000` : `https://sockets.globaldata.link`,
        },
        homesafealerts: {
            server: hsa_server,
            url: `${hsa_server}/api/v${hsa_version}`,
        },
        omnishield: {
            server: omnishield_server,
            url: `${omnishield_server}/api/v${omnishield_version}`,
            sockets: dev_env ? `${omnishield_server}:4500` : 'https://sockets.crosslinkserver.com',
        }
    },
    mapbox: 'pk.eyJ1IjoiZ2hzLXJpZGEiLCJhIjoiY2tpcTU0ZW9hMGQzYTJybXYxcGU5NHRrdSJ9.-KPLGweAXcpYfrJJoUB5NA',
    version: api_version,
    server: server,
    sockets: dev_env ? `${server}:3500` : `https://sockets.aftplatform.com`,
    stripe: 'pk_live_51IXdy9G6RZW42dLLNWUYUavOUd7i4I8XmTwO819lk1cvXHLsQYPEz8nPQHqa0FE9HFiEWLJeP8PRyGtYpRgwaN5g00YlzPewN3', //dev_env ? 'pk_test_51IXdy9G6RZW42dLL51H4CKaukROsRhXUgs9AnLmQgJOiE3R6ZK8LPM7inn6KUBuHET0o8PVhkDNAHyD1Zgtna67a00N5YaAIrD' : 'pk_live_51IXdy9G6RZW42dLLNWUYUavOUd7i4I8XmTwO819lk1cvXHLsQYPEz8nPQHqa0FE9HFiEWLJeP8PRyGtYpRgwaN5g00YlzPewN3',
    url: `${server}/api/v${api_version}`,
    website: 'https://ghscompanies.com'
}
