import moment from 'moment-timezone';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class NotificationClass {

    attachment = null;
    category = null;
    date = null;
    for_level = null;
    from_user = null;
    id = null;
    message = null;
    payload = null;
    title = null;
    to_user = null;
    unread = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.attachment = props.attachment;
        this.category = props.category;
        this.date = props.date ? moment.utc(props.date).local() : null;
        this.for_level = props.for_level;
        this.from_user = props.from_user ? User.create(props.from_user) : null;
        this.id = props.id;
        this.message = props.message;
        this.payload = props.payload;
        this.title = props.title;
        this.to_user = props.to_user ? User.create(props.to_user) : null;
        this.unread = props.unread;
        return this;
    }

    setAsRead = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/notifications/', {
                    type: 'set_as_read',
                    id: this.id
                });
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }
}

export default {
    create: props => new NotificationClass().create(props)
};