import React, { useEffect, useState } from 'react';

import moment from 'moment';
import update from 'immutability-helper';

import Appearance from 'styles/Appearance.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const MultipleTextField = props => {

    const { max, onChange, onRenderItem, useIdentifiers, value } = props;

    const [items, setItems] = useState([]);
    const [text, setText] = useState(null);

    const onAddItem = () => {
        let next = update(items, {
            $push: useIdentifiers !== true ? [text] : [{
                id: `${moment().unix()}-${Utils.randomString(5)}`,
                title: text
            }]
        });
        setText(null);
        setItems(next);
        if(typeof(onChange) === 'function') {
            onChange(next);
        }
    }

    const onRemoveItem = index => {
        let next = update(items, { $splice: [[ index, 1 ]] });
        setItems(next);
        if(typeof(onChange) === 'function') {
            onChange(next);
        }
    }

    const getAppendContent = () => {

        // no icon is needed if text has not been provided
        if(!text || text.length === 0) {
            return null;
        }

        // no icon is needed if the optional max value has been reached
        if(max && max === items.length) {
            return null;
        }
        
        return Views.icon.right('new', onAddItem);
    }

    useEffect(() => {
        if(useIdentifiers !== true) {
            setItems(Array.isArray(value) ? value : []);
            return;
        }
        let values = value && Array.isArray(value) && value.map(val => {
            if(typeof(val) === 'object') {
                return val;
            }
            return {
                id: `${moment().unix()}-${Utils.randomString(5)}`,
                title: val
            }
        });
        setItems(values || []);
    }, [value]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}>
            <TextField
            {...props}
            appendContent={getAppendContent()}
            onChange={setText}
            value={text} />
            {items.length > 0 && (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginTop: 8
                }}>
                    {items.map((item, index) => {
                        if(typeof(onRenderItem) === 'function') {
                            return onRenderItem(item, index, items, { onRemoveItem });
                        }
                        return (
                            Views.row({
                                bottomBorder: index !== items.length - 1,
                                key: index,
                                label: typeof(item) === 'string' ? item : item.title,
                                value: Views.icon.right('red-x', onRemoveItem.bind(this, index))
                            })
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default MultipleTextField;
