import React, { useEffect, useState } from 'react';
import moment from 'moment';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import FlipChart from 'classes/FlipChart.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer from 'structure/Layer.js';
import Panel from 'structure/Panel.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

export const FlipChartPresentations = ({ published = true }, { index, options, utils }) => {

    const panelID = 'flipchart_presentations';
    const limit = 5;

    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [presentations, setPresentations] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onNewPresentation = () => {
        utils.alert.show({
            title: 'New Presentation',
            message: `We need to redirect you to AFTcreate before you can start creating a new presentation.`,
            buttons: [{
                key: 'confirm',
                title: 'Take Me There',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    window.open('https://aftplatform.com/dashboard/builder/index.php');
                    return;
                }
            }
        });
    }

    const onPresentationClick = presentation => {
        utils.layer.open({
            id: `flipchart_presentation_details_${presentation.id}`,
            abstract: Abstract.create({
                type: 'flipchart_presentation',
                object: presentation
            }),
            Component: FlipChartPresentationDetails
        })
    }

    const getButtons = () => {
        let { level } = utils.user.get();
        return level <= User.levels.get().admin && [{
            key: 'new',
            title: 'New Presentation',
            style: 'default',
            onClick: onNewPresentation
        }];
    }

    const getContent = () => {
        if(presentations.length === 0){
            return (
                Views.entry({
                    title: 'Nothing to see here',
                    subTitle: 'No presentations were found in the system',
                    hideIcon: true,
                    bottomBorder: false
                })
            )
        }
        return presentations.map((presentation, index) => {
            return (
                Views.entry({
                    key: index,
                    title: presentation.title,
                    subTitle: `Last Updated: ${Utils.formatDate(presentation.date)}`,
                    icon: {
                        path: 'images/flipchart-presentation-icon-small.png'
                    },
                    badge: [{
                        text: `Version ${presentation.version}`,
                        color: Appearance.colors.primary()
                    },{
                        text: presentation.active ? null : 'Not Active',
                        color: Appearance.colors.grey()
                    }],
                    firstItem: index === 0,
                    singleItem: presentations.length === 1,
                    lastItem: index === presentations.length - 1,
                    bottomBorder: index !== presentations.length - 1,
                    onClick: onPresentationClick.bind(this, presentation)
                })
            )
        });
    }

    const getTitle = () => {
        if(utils.user.get().level <= User.levels.get().admin) {
            return `${published ? 'Published' : 'Unpublished'} Presentations`;
        }
        return 'Presentations';
    }

    const fetchPresentations = async () => {
        try {
            setLoading(true);
            let { presentations, paging } = await Request.get(utils, '/flipchart/', {
                limit: limit,
                offset: offset,
                published: published,
                search_text: searchText,
                type: 'all_presentations'
            });

            setLoading(false);
            setPaging(paging);
            setPresentations(presentations.map(presentation => FlipChart.Presentation.create(presentation)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the presentations list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchPresentations();
    }, [searchText, offset]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'flipchart_presentation', {
            onFetch: fetchPresentations,
            onUpdate: abstract => {
                setPresentations(presentations => {
                    return presentations.map(presentation => {
                        return presentation.id === abstract.getID() ? abstract.object : presentation;
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, [])

    return (
        <Panel
        panelID={panelID}
        name={getTitle()}
        index={index}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading,
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: nextOffset => {
                    setOffset(nextOffset);
                }
            },
            search: {
                placeholder: 'Search by id or presentation name...',
                onChange: text => {
                    setOffset(0);
                    setSearchText(text);
                }
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const FlipChartPresentationDetails = ({ abstract, index, options, utils }) => {

    const layerID = `flipchart_presentation_details_${abstract.getID()}`;
    const [dealerships, setDealerships] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [preferences, setPreferences] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [regions, setRegions] = useState([]);
    const [users, setUsers] = useState([]);

    const onDeletePresentation = () => {
        utils.alert.show({
            title: `Delete ${abstract.object.title}`,
            message: `Are you sure that you want to delete this presentation? This action can not be undone.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeletePresentationConfirm();
                    return;
                }
            }
        })
    }

    const onDeletePresentationConfirm = async () => {
        try {
            setLoading('options');
            await Utils.sleep(1);

            await Request.post(utils, '/flipchart/', {
                type: 'delete_presentation',
                id: abstract.getID()
            });

            setLoading(false);
            utils.content.fetch('flipchart_presentation');
            utils.alert.show({
                title: 'All Done!',
                message: `This presentation has been deleted and will no longer be available for download.`,
                onClick: () => setLayerState('close')
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this presentation. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onOptionsClick = evt => {
        let { level } = utils.user.get();
        utils.sheet.show({
            items: [{
                key: 'preferences',
                title: `Set Dealership Preferences`,
                style: 'default'
            },{
                key: 'status',
                title: `Set as ${abstract.object.active ? 'Inactive' : 'Active'}`,
                style: abstract.object.active ? 'destructive' : 'default',
                visible: level <= User.levels.get().admin
            },{
                key: 'delete',
                title: 'Delete',
                style: 'destructive',
                visible: level <= User.levels.get().admin
            }],
            target: evt.target
        }, key => {
            if(key === 'delete') {
                onDeletePresentation();
                return;
            }
            if(key === 'preferences') {
                onSetPreferences();
                return;
            }
            if(key === 'status') {
                onSetStatus();
                return;
            }
        });
    }

    const onSetPreferences = () => {
        utils.layer.open({
            abstract: abstract,
            Component: FlipChartPresentationPreferences,
            id: `flipchart_presentation_preferences_${abstract.getID()}`
        });
    }

    const onSetStatus = () => {
        utils.alert.show({
            title: `Set as ${abstract.object.active ? 'Inactive' : 'Active'}`,
            message: `Are you sure that you want to set this presentation as ${abstract.object.active ? 'inactive' : 'active'}? ${abstract.object.active ? 'This will prevent users in the field from downloading this presentation.' : 'This will allow all eligible users to download this presentation in the FlipChart app.'}.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Do Not Change',
                style: abstract.object.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetStatusConfirm();
                    return;
                }
            }
        })
    }

    const onSetStatusConfirm = async () => {
        try {
            setLoading('options');
            await Utils.sleep(1);

            let status = !abstract.object.active;
            await Request.post(utils, '/flipchart/', {
                type: 'set_presentation_status',
                id: abstract.getID(),
                status: status
            });

            setLoading(false);
            abstract.object.active = status;
            utils.content.update(abstract);

            utils.alert.show({
                title: 'All Done!',
                message: `This presentation has been set as ${status ? 'active' : 'inactive'}`
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this presentation. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getButtons = () => {

        // return multiple options for admins to interact with this presentation 
        let { level } = utils.user.get();
        if(level <= User.levels.get().admin) {
            return [{
                key: 'options',
                text: 'Options',
                color: 'secondary',
                loading: loading === 'options',
                onClick: onOptionsClick
            }];
        }
        
        // only return option to set dealership preferences for dealers and directors
        if(preferences.length > 0 && level <= User.levels.get().dealer) {
            return [{
                key: 'preferences',
                text: 'Set Dealership Preferences',
                color: 'secondary',
                loading: loading === 'options',
                onClick: onSetPreferences
            }];
        }

        // return no options for advisors
        return null;
    }

    const getFields = () => {

        let presentation = abstract.object;
        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: presentation.id
            },{
                key: 'version',
                title: 'Version',
                value: presentation.version
            },{
                key: 'title',
                title: 'Title',
                value: presentation.title
            },{
                key: 'description',
                title: 'Description',
                value: presentation.description
            },{
                key: 'created',
                title: 'Created',
                value: presentation.date ? moment(presentation.date).format('MMMM Do, YYYY') : null
            },{
                key: 'last_updated',
                title: 'Last Updated',
                value: presentation.last_updated ? moment(presentation.last_updated).format('MMMM Do, YYYY') : null
            },{
                key: 'pages',
                title: 'Pages',
                value: `${presentation.pages.length} ${presentation.pages.length === 1 ? 'page' : 'pages'}`
            },{
                key: 'status',
                title: 'Status',
                value: presentation.active ? 'Active' : 'Not Active'
            }]
        }];

        if(utils.user.get().level <= User.levels.get().admin) {
            items = items.concat([{
                key: 'config',
                title: 'Configuration',
                items: [{
                    key: 'keywords',
                    title: 'Keywords',
                    value: presentation.options.keywords && Utils.oxfordImplode(presentation.options.keywords)
                },{
                    key: 'temperature_units',
                    title: 'Temperature Units',
                    value: presentation.options.temperature_units === 'c' ? 'Celsius' : 'Fahrenheit'
                }]
            },{
                key: 'restrictions',
                title: 'Restrictions',
                items: [{
                    key: 'level',
                    title: 'Account Type',
                    value: getLevels()
                },{
                    key: 'dealerships',
                    title: 'Dealerships',
                    value: Utils.oxfordImplode(dealerships)
                },{
                    key: 'division',
                    title: 'Divisions',
                    value: Utils.oxfordImplode(divisions)
                },{
                    key: 'programs',
                    title: 'Programs',
                    value: Utils.oxfordImplode(programs)
                },{
                    key: 'region',
                    title: 'Regions',
                    value: Utils.oxfordImplode(regions)
                },{
                    key: 'users',
                    title: 'Specific Users',
                    value: Utils.oxfordImplode(users),
                }]
            }]);
        }
        return items;
    }

    const getLevels = () => {
        let { levels = [] } = abstract.object.options || {};
        let targets = levels.map(level => User.levels.toText(level));
        return Utils.oxfordImplode(targets);
    }

    const setupPreferences = async () => {
        try {
            // set preferences
            let { custom } = abstract.object.options || {};
            setPreferences(custom || []);

            // prevent moving forward if user is not an admin
            if(utils.user.get().level > User.levels.get().admin) {
                return;
            }

            // fetch extended details for presentation
            setLoading(true);
            let { dealerships, divisions, programs, regions, users } = await Request.get(utils, '/flipchart/', {
                id: abstract.getID(),
                published: abstract.object.published,
                type: 'presentation_ext_details'
            });

            setLoading(false);
            setDealerships(dealerships || []);
            setDivisions(divisions || []);
            setPrograms(programs);
            setRegions(regions || []);
            setUsers(users || []);


        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving some of the information for this presentation. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    useEffect(() => {
        setupPreferences();
    }, []);

    return (
        <Layer
        id={layerID}
        buttons={getButtons()}
        index={index}
        title={`${abstract.getTitle()} Details`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <FieldMapper 
            fields={getFields()} 
            utils={utils} />
        </Layer>
    )
}

export const FlipChartPresentationPreferences = ({ abstract, index, options, utils }) => {

    const layerID = `flipchart_presentation_preferences_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [preferences, setPreferences] = useState({});
    const [whiteLabelPreferences, setWhiteLabelPreferences] = useState(null);

    const onUpdatePreferences = async () => {
        try {
            setLoading(true);
            await validateRequiredFields(getFields);

            await Request.post(utils, '/flipchart/', {
                id: abstract.getID(),
                preferences: preferences,
                type: 'update_presentation_preferences',
                white_label_preferences: whiteLabelPreferences
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'The dealership preferences for this presentation have been updated. These changes will be available for all newly downloaded presentations. Users who have already downloaded the presentation can use the "Re-Sync Preferences" option in the FlipChart mobile app to receive the new preferences.',
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the dealership preferences for this presentation. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        setPreferences(prev => ({ ...prev, ...props }));
    }

    const onUpdateWhiteLabelTarget = props => {
        setWhiteLabelPreferences(prev => ({ ...prev, ...props }));
    }

    const getButtons = () => {
        return [{
            color: 'dark',
            key: 'cancel',
            onClick: setLayerState.bind(this, 'close'),
            text: 'Cancel'
        },{
            color: 'primary',
            key: 'confirm',
            onClick: onUpdatePreferences,
            text: 'Save Changes'
        }];
    }

    const getComponentProps = entry => {
        let type = entry.nodes && entry.nodes[0].type;
        switch(type) {
            case 'ImageElement':
            return {
                component: 'image_picker',
                props: {
                    requirements: entry.requirements && {
                        dimensions: entry.requirements
                    }
                }
            };

            case 'VideoElement':
            return {
                component: 'file_picker',
                props: {
                    fileTypes: ['mp4'],
                    requirements: entry.requirements
                }
            };

            default:
            return { component: entry.component || 'textfield' };
        }
    }

    const getFields = () => {

        let custom = abstract.object.options.custom || [];
        let items = [];

        // prepare presentation preferences if applicable
        if(custom.length > 0) {
            items.push({
                key: 'preferences',
                title: 'Preferences',
                items: custom.filter(entry => {
                    return entry.nodes.find(node => node.enabled);
                }).sort((a,b) => {
                    return a.title.localeCompare(b.title);
                }).map(entry => ({
                    ...entry,
                    ...getComponentProps(entry),
                    key: entry.id,
                    required: entry.nodes.find(node => node.required) ? true : false,
                    value: preferences[entry.id],
                    onChange: val => onUpdateTarget({ [entry.id]: val })
                }))
            })
        }

        // add white label preferences field if applicable
        if(whiteLabelPreferences) {
            items.push({
                key: 'white_label_preferences',
                title: 'White Label Preferences',
                items: Object.values(whiteLabelPreferences).map(getWhiteLabelComponentProps)
            })
        }
        return items;
    }

    const getWhiteLabelComponentProps = (entry, index) => {
        switch(entry.id) {
            case 'partner_organizations':
            return {
                component: 'multiple_textfield',
                key: index,
                onChange: items => {
                    onUpdateWhiteLabelTarget({ 
                        [entry.id]: update(entry, {
                            value: {
                                $set: items
                            }
                        }) 
                    })
                },
                props: {
                    max: 5,
                    placeholder: 'Type a name of an organization and click the plus icon when finished'
                },
                required: false,
                subTitle: 'Up to 5 organizations allowed',
                title: 'Partner Organizations',
                value: entry.value
            }

            default:
            return null;
        }
    }

    const fetchPreferences = async () => {
        try {
            setLoading(true);
            let { preferences, white_label_preferences } = await Request.get(utils, '/flipchart/', {
                id: abstract.getID(),
                type: 'presentation_preferences'
            });

            // determine if no preferences were found for this presentation
            if(!preferences && !white_label_preferences) {
                throw new Error('This presentation does not support dealership specific preferences');
            }

            console.log(abstract.object.options.custom );
            
            // end loading and update local state
            setLoading(false);
            setPreferences(preferences || {});
            setWhiteLabelPreferences(white_label_preferences);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the dealership preferences for this presentation. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    useEffect(() => {
        fetchPreferences();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'FlipChart Presentation Preferences'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
        </Layer>
    )
}
